import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import {
  fetchCurrency,
  getHotelQuote,
  getHotelRooms,
  initializeHotelRooms,
  updateQuoteRoomIds,
} from "../../../../services/Reservation/hotelReservationSlice";

import { colors } from "../../../../utils/colors";
import { getLanguageId, images, svg } from "../../../../utils/constants";
import { Loader } from "../../../../utils/styles";
import { MoonLoader } from "react-spinners";

import RoomCard from "./components/RoomCard";
import { useState } from "react";
import Select from "react-dropdown-select";
import { currOptions } from "../Currencies/CurrenciesOptions";
import { useNavigate } from "react-router-dom";

const RoomSelection = ({ setStepSelected, setChildrenAges }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [visibleItems, setVisibleItems] = useState(10);
  const [checkedRooms, setCheckedRooms] = useState([]);

  const reservationData = useSelector((state) => state.hotelReservation);
  const hotelRooms = useSelector((state) => state.hotelReservation.hotelRooms);
  const hotelReservation = useSelector((state) => state.hotelReservation);
  const loading = useSelector((state) => state.hotelReservation.loading);
  const language = useSelector((state) => state.language);
  const options = currOptions;

  const convertAgeToString = (childObj) => {
    return {
      ...childObj,
      age: childObj.age.join("-"),
    };
  };

  const formatDataArray = (dataArray) => {
    return dataArray.map((item) => {
      return {
        ...item,
        child:
          item.child.number === 0 ? item.child : convertAgeToString(item.child),
      };
    });
  };

  useEffect(() => {
    const searchRoomIds = formatDataArray(hotelReservation.roomIds);
    dispatch(
      getHotelRooms({
        fromDate: hotelReservation.arrivalDate,
        toDate: hotelReservation.departureDate,
        roomIds: searchRoomIds,
        language: getLanguageId(language.lang.toString()),
      })
    );
  }, [
    dispatch,
    hotelReservation.arrivalDate,
    hotelReservation.departureDate,
    hotelReservation.roomIds,
    language,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY + window.innerHeight >= document.body.scrollHeight) {
        setVisibleItems((prev) => prev + 5);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const initializeAdultsAndChilds = (
    adultsNumber,
    childsNumbers,
    roomId,
    boardingId
  ) => {
    const emptyNames = [];

    const adultCount = adultsNumber;
    const childCount = childsNumbers;

    for (let i = 0; i < adultCount; i++) {
      emptyNames.push(["", "", "adult", roomId, boardingId]);
    }

    for (let j = 0; j < childCount; j++) {
      emptyNames.push(["", "", "child", roomId, boardingId]);
    }

    return { details: emptyNames };
  };

  const handleBookNow = async () => {
    const quoteRoomIds = [];

    for (let i = 0; i < checkedRooms.length; i++) {
      const room = checkedRooms[i];
      const count = parseInt(room["room@count"]) || 1;

      var customers = initializeAdultsAndChilds(
        parseInt(room.roomAdult),
        parseInt(room.roomChild["#text"]),
        parseInt(room["room@id"]),
        parseInt(room["@id"])
      );

      for (let j = 0; j < count; j++) {
        if (room.roomChild["@age"] != "0")
          setChildrenAges((previousAges) => [
            ...previousAges,
            room.roomChild["@age"],
          ]);

        quoteRoomIds.push({
          id: parseInt(room["room@id"]),
          adult: parseInt(room.roomAdult),
          child: {
            age: parseInt(room.roomChild["@age"]),
            number: parseInt(room.roomChild["#text"]),
          },
          boardingId: parseInt(room["@id"]),
          boarding: {
            id: parseInt(room["@id"]),
            nonRefundable: room.NonRefundable,
            rate: room.Rate["#text"] / count,
          },
          count: parseInt(room["room@count"]),
          customers: customers,
        });
      }
    }

    dispatch(
      getHotelQuote({
        fromDate: hotelReservation.arrivalDate,
        toDate: hotelReservation.departureDate,
        hotelId: "897",
        roomIds: quoteRoomIds,
        language: getLanguageId(language.lang.toString()),
      })
    );
    dispatch(updateQuoteRoomIds(quoteRoomIds));
    setStepSelected(1);
  };

  const handleChange = (selectedItems) => {
    setCheckedRooms([]);
    dispatch(fetchCurrency(selectedItems[0]));
  };

  const [sortedRooms, setSortedRooms] = useState([]);

  useEffect(() => {
    if (
      hotelRooms &&
      hotelRooms.HotelSearchRES &&
      hotelRooms.HotelSearchRES.Hotels &&
      hotelRooms.HotelSearchRES.Hotels.Hotel &&
      hotelRooms.HotelSearchRES.Hotels.Hotel.rooms.room != null
    ) {
      const roomArray = [...hotelRooms.HotelSearchRES.Hotels.Hotel.rooms.room];
      const convertedData = [];
      roomArray.forEach((room) => {
        let boardingArray = [];
        if (Array.isArray(room.Boardings.Boarding)) {
          boardingArray = room.Boardings.Boarding;
        } else if (
          room.Boardings.Boarding &&
          typeof room.Boardings.Boarding === "object"
        ) {
          boardingArray = [room.Boardings.Boarding];
        }
        boardingArray.forEach((boarding) => {
          const convertedBoarding = {
            [`room@id`]: room["@id"],
            [`room@count`]: room["@count"],
            roomTitle: room.Title,
            roomAdult: room.Adult,
            roomChild: room.Child,
            roomAvailableQuantity: room.AvailableQuantity,
            "@id": boarding["@id"],
            Title: boarding.Title,
            Available: boarding.Available,
            DownPayment: boarding.DownPayment,
            Rate: boarding.Rate,
            RateWithoutPromotion: boarding.RateWithoutPromotion,
            NonRefundable: boarding.NonRefundable,
            CancellationPolicy: boarding.CancellationPolicy,
          };
          convertedData.push(convertedBoarding);
        });
      });

      const sortedData = convertedData.sort((a, b) => {
        return parseFloat(a.Rate["#text"]) - parseFloat(b.Rate["#text"]);
      });

      const finalData = {
        Boardings: {
          Boarding: sortedData,
        },
      };
      setSortedRooms(finalData);
      //console.log(finalData);
    }
  }, [hotelRooms]);

  return (
    <RoomSelectionContainer>
      <CircleOutlineDecoration />
      <BackgroundContainer />

      {hotelRooms && (
        <>
          <SelectPositionContainer>
            <SelectContainer>
              <Select
                options={options}
                values={[reservationData.defaultCurrency]}
                onChange={handleChange}
                placeholder="Select an option"
                searchable={false}
                backspaceDelete={false}
              />
            </SelectContainer>
          </SelectPositionContainer>
          {hotelRooms &&
          hotelRooms.HotelSearchRES.Hotels["@count"] === "1" &&
          hotelRooms.HotelSearchRES.Hotels.Hotel.PromotionTitle.length > 1 ? (
            <HotelPromotionMsg>
              {hotelRooms.HotelSearchRES.Hotels.Hotel.PromotionTitle}
            </HotelPromotionMsg>
          ) : null}
          <RoomSelectionTitleRow>
            <RoomSelectionTitle> {t("roomSelection")}</RoomSelectionTitle>
            {!loading &&
              hotelRooms &&
              hotelRooms.HotelSearchRES.Hotels["@count"] === "1" && (
                <BookButtonDesktop
                  disabled={checkedRooms.length < 1}
                  onClick={handleBookNow}
                >
                  <svg.bookIcon />
                  {t("bookNow")}
                </BookButtonDesktop>
              )}
          </RoomSelectionTitleRow>
          {loading && (
            <Loader>
              <MoonLoader color={colors.ocean} size="30px" />
            </Loader>
          )}
          {!loading && hotelRooms ? (
            hotelRooms.HotelSearchRES.Hotels["@count"] === "1" ? (
              <>
                {sortedRooms &&
                  sortedRooms.Boardings &&
                  sortedRooms.Boardings.Boarding.slice(0, visibleItems).map(
                    (item, index) => (
                      <RoomCard
                        key={index}
                        hotel={hotelRooms.HotelSearchRES}
                        room={item}
                        setCheckedRooms={setCheckedRooms}
                      />
                    )
                  )}
              </>
            ) : (
              <div>{t("noRoomsAvailable")}</div>
            )
          ) : null}
        </>
      )}
      {/* {hotelRooms && hotelRooms?.["?xml"] == undefined && (
        <ErrorDiv>
          <ErrorImg src={images.errorImg} />
          <p>{t("errorMessage")}</p>
          <GoBack
            onClick={() => {
              dispatch(initializeHotelRooms());
              navigate(-1);
            }}
          >
            {t("goBackHome")}
          </GoBack>
        </ErrorDiv>
      )} */}
    </RoomSelectionContainer>
  );
};

const RoomSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 75px 35px;
  max-width: 620px;
  gap: 40px;
  align-self: center;
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: auto;
    align-self: auto;
    padding: 75px 80px;
  }
`;

const RoomSelectionTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: none;
  z-index: -1;
  top: 0;
  right: 0;
  width: 650px;
  height: 321px;
  background-color: ${colors.ocean8};

  @media only screen and (min-width: 992px) {
    display: flex;
  }
`;

const CircleOutlineDecoration = styled(svg.circleOutlineDecoration)`
  position: absolute;
  top: 300px;
  right: 0;
`;

const HotelPromotionMsg = styled.div`
  background-color: ${colors.gold};
  color: ${colors.white};
  padding: 20px;
  border-radius: 14px;
  font-size: 13px;
`;

const RoomSelectionTitleRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const bookButton = css`
  display: flex;
  background-color: ${colors.purple};
  border-radius: 10px;
  padding: 16px 40px;
  border: 0;
  outline: none;
  color: ${colors.white};
  font-weight: 600;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
`;

const BookButtonDesktop = styled.button`
  ${bookButton};
  display: flex;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SelectPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const SelectContainer = styled.div`
  width: 180px;
`;

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorImg = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
`;

const GoBack = styled.button`
  background-color: ${colors.purple};
  color: ${colors.white};
  border-radius: 10px;
  padding: 14px 26px;
  border: none;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
`;

export default RoomSelection;
